<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 훈련평가 상세 -->
          <c-card title="훈련평가 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="planResult"
                  :mappingType="saveType"
                  label="일괄저장"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <c-field
                      required
                      :editable="editable"
                      :data="planResult"
                      type="dept_user" 
                      label="평가자" 
                      name="evaluationUserId" 
                      v-model="planResult.evaluationUserId" />
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <c-textarea
                      :editable="editable"
                      :rows="4"
                      label="평가내용"
                      name="evaluationContents"
                      v-model="planResult.evaluationContents">
                    </c-textarea>
                  </div>
                </div>
                <div class="row q-pt-sm">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <c-table
                      ref="table"
                      title="훈련평가항목 목록"
                      :columns="grid.columns"
                      :data="planResult.trainingEval"
                      noDataLabel="상황전개에 따른 항목이 나옵니다."
                      :editable="editable"
                      :gridHeight="gridHeight"
                      :usePaging="false"
                      :isExcelDown="false"
                      :hideBottom="false"
                      selection="multiple"
                      rowKey="emergTrainingEvalItemId"
                    >
                      <template slot="table-button">
                        <q-btn-group outline >
                          <c-btn v-show="editable" :showLoading="false" label="추가" icon="add" @btnClicked="addTraining" />
                          <c-btn v-show="editable" :showLoading="false" label="제외" icon="remove" @btnClicked="removeTraining" />
                        </q-btn-group>
                      </template>
                    </c-table>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 q-mt-md">
          <template>
            <c-upload 
              :isPhotoView="true"
              :attachInfo="attachInfo"
              :editable="editable">
            </c-upload>
          </template>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'traning-assess',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        emergTrainingPlanId: '',
        emergTrainingStatusCd: '',
        trainingScenarioId: '',
      }),
    },
    isApprDisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      planResult: {
        emergTrainingPlanId: '',
        emergTrainingName: '',
        trainingScenarioId: '',
        emergTrainingStatusCd: '',
        evaluationUserId: '',
        evaluationContents: '',
        trainingEval: [],
        deleteTrainingEval: [],
        evalItemsResultCd: null,
        regUserId: '',
        chgUserId: '',
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EMERGENCY_TRAINING_RESULT',
        taskKey: '',
      },
      saveUrl: '',
      saveType: 'PUT',
      isSave: false,
      getItemUrl: '',
      detailUrl: '',
      grid: {
        columns: [],
        data: [],
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let height = window.innerHeight - 550;
      if (height < 300) {
        height = 300;
      }
      return String(height) + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.getItemUrl = selectConfig.sop.eap.training.plan.getItem.url;
      this.detailUrl = selectConfig.sop.eap.planResult.result.get.url;
      this.saveUrl = transactionConfig.sop.eap.planResult.result.update.url
      // code setting
      // list setting
      this.getDetail();
      this.setHeader();
    },
    getDetail() {
      if (this.popupParam.emergTrainingPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.emergTrainingPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.planResult = _result.data;
          this.$set(this.attachInfo, 'taskKey', this.popupParam.emergTrainingPlanId)
        },);
      }
    },
    
    setHeader() {
      this.$comm.getComboItems('EVAL_ITEMS_RESULT_CD', false).then(_result => {
        this.grid.columns = [
          {
            name: 'situationDevelopmentName',
            field: 'situationDevelopmentName',
            label: '상황전개',
            align: 'left',
            style: 'width:400px',
            sortable: false,
            type: 'text'
          },
          {
            name: 'evalItems',
            field: 'evalItems',
            label: '평가항목',
            align: 'left',
            style: 'width:400px',
            sortable: false,
            type: 'text'
          },
          {
            setHeader: true,
            name: 'evalItemsResultCd',
            field: 'evalItemsResultCd',
            label: '평가결과',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'select',
            comboItems: _result
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'text'
          },
          {
            required: true,
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            sortable: true,
            style: 'width:80px',
            type: 'number',
          },
        ]
      });
    },
    savePlan() {
      this.saveUrl = transactionConfig.sop.eap.planResult.result.update.url;
      this.saveType = 'PUT';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.$comm.validTable(this.grid.columns, this.planResult.trainingEval)) { 
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.planResult.regUserId = this.$store.getters.user.userId
                this.planResult.chgUserId = this.$store.getters.user.userId
                this.isSave = !this.isSave
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.attachInfo, 'taskKey',  this.popupParam.emergTrainingPlanId)
      this.$set(this.attachInfo, 'isSubmit', this.popupParam.emergTrainingPlanId)
      this.getDetail();
    },
    addTraining() {
      this.planResult.trainingEval.push({
        emergTrainingPlanId: this.param.emergTrainingPlanId,
        emergTrainingEvalItemId: uid(),
        situationDevelopmentName: '',
        evalItems: '',
        evalItemsResultCd: null,
        remark: '',
        sortOrder: 0,
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    removeTraining() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.planResult.deleteTrainingEval) {
            this.planResult.deleteTrainingEval = []
          }
          if (this.$_.findIndex(this.planResult.deleteTrainingEval, { emergTrainingEvalItemId: item.emergTrainingEvalItemId }) === -1
            && item.editFlag !== 'C') {
              this.planResult.deleteTrainingEval.push(item)
          }
          this.planResult.trainingEval = this.$_.reject(this.planResult.trainingEval, item)
        })
      }
    },
  }
};
</script>